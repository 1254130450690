<template>
  <div class="formulate-input">
    <div class="flex items-center gap-4">
      <label :for="context.id" class="formulate-input-label formulate-input-label--before mb-2">{{
        label
      }}</label>
      <div v-tooltip="!!context.model && translate('misc.removeContent')">
        <v-btn
          :disabled="!!context.model || context.attributes.disabled"
          color="primary"
          fab
          outlined
          @click="initialized"
          v-tooltip="translate('misc.copyInitial')"
          class="mb-2"
          x-small
          ><v-icon small>far fa-clone</v-icon></v-btn
        >
      </div>
    </div>
    <div
      :class="`formulate-input-element formulate-input-element--${subtype}`"
      :data-type="subtype"
    >
      <v-textarea
        :disabled="context.attributes.disabled"
        v-model="context.model"
        outlined
        :rows="rows"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  methods: {
    initialized() {
      this.context.model = this.initial;
    },
  },
  computed: {
    label() {
      return this.context.attributes?.customLabel;
    },
    subtype() {
      return this.context.attributes.subtype;
    },
    rows() {
      return this.context.attributes.rows;
    },
    initial() {
      return this.externalTranslate(this.context.attributes.initial);
    },
  },
};
</script>

<style>
</style>