// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMX2Jfkp_zS8Y8MCiO-Hxo-db0riBeX00",
  authDomain: "living-evidence.firebaseapp.com",
  projectId: "living-evidence",
  storageBucket: "living-evidence.appspot.com",
  messagingSenderId: "469609837673",
  appId: "1:469609837673:web:fad1b005bc6eb5d9db1ef8",
  measurementId: "G-H8TSNW05LR",
};

export { firebaseConfig };
