<template>
  <div>
    <slot name="label"
      ><div class="flex items-end gap-2">
        <label class="formulate-input-label formulate-input-label--before m-0">
          {{ translate("misc.manager") }}
        </label>

        <v-btn
          v-tooltip="translate('misc.manager')"
          :disabled="editModeActivate || isDisabled"
          @click="handleAddNewMember"
          small
          icon
          color="primary"
          ><v-icon small>fas fa-plus-circle</v-icon></v-btn
        >
      </div></slot
    >
    <FormulateForm
      @submit-raw="handleValidation"
      name="members"
      v-model="newUser"
      @submit="handleConfirmMember"
    >
      <div v-if="context.model.length > 0">
        <div v-for="(user, index) in context.model" :key="index">
          <div class="flex items-center flex-wrap">
            <!-- EMAIL -->
            <div class="w-full md:w-1/2">
              <div v-if="!user.edit">
                {{ isMeEmail(user.email) ? "(You)" : "" }} <span>{{ user.email }}</span>
              </div>
              <div v-else>
                <FormulateInput
                  :disabled="isDisabled"
                  v-model="user.email"
                  name="email"
                  validation="required|email"
                  type="email"
                  placeholder="test@test.com"
                />
              </div>
            </div>
            <!-- ROLE -->
            <div class="w-full md:w-1/2">
              <div class="flex gap-4 items-center">
                <div>
                  <div v-show="!user.edit" class="flex gap-2">
                    <v-btn
                      :disabled="user.blocked || isDisabled"
                      type="button"
                      @click="(e) => handleUpdateUser(index)"
                      small
                      icon
                      color="primary"
                      ><v-icon small>fas fa-cog</v-icon></v-btn
                    >
                    <v-btn
                      :disabled="user.blocked || isDisabled"
                      type="button"
                      @click="(e) => handleDelete(index)"
                      small
                      icon
                      color="orange"
                      ><v-icon small>fas fa-trash</v-icon></v-btn
                    >
                  </div>
                  <div v-show="user.edit" class="flex gap-2">
                    <v-btn type="submit" small icon color="primary"
                      ><v-icon small>fas fa-check</v-icon></v-btn
                    >
                    <v-btn
                      :disabled="isDisabled"
                      type="button"
                      @click="(e) => handleDelete(index)"
                      small
                      icon
                      color="orange"
                      ><v-icon small>fas fa-trash</v-icon></v-btn
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- OPTIONS -->
          </div>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import configurations from "@/utils/configurations";
// TODO: CHECK THIS COMPONENT
// TODO: TRANSLATION
// TODO: CUSTOM VALIDATION EMAIL IS TAKEN
// TODO: PERPETUAL EDIT MODE
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newUser: {
        email: "",
      },
    };
  },

  methods: {
    handleValidation(data) {
      const validation = data.form.isValid;

      this.context.isValid = validation;
    },
    handleDelete(index) {
      this.context.model = this.context.model.filter((el, i) => i != index);
    },
    handleUpdateUser(index) {
      this.context.model = this.context.model.map((el, i) => {
        if (index !== i) return el;

        this.newUser.email = el.email;
        return {
          ...el,
          edit: true,
        };
      });
    },
    handleAddNewMember() {
      this.newUser.email = "";
      this.context.model = [...this.context.model, { email: "", edit: true }];
    },
    handleConfirmMember({ email }) {
      this.newUser.email = "";
      this.context.model = this.context.model.map((el) => {
        if (!el.edit) return el;

        return {
          ...el,
          email,
          edit: false,
        };
      });
    },
  },
  computed: {
    isDisabled() {
      return this.context.attributes?.disabled;
    },
    editModeActivate() {
      try {
        if (this.isDisabled) {
          return false;
        }
        return this.context.model.some((el) => el.edit);
      } catch (error) {
        return false;
      }
    },

    options() {
      let currentOptions = { ...configurations.roles };

      // TRANSLATE OPTIONS
      Object.entries(configurations.roles).map(([key, opt]) => {
        currentOptions[key] = this.translate(opt);
      });

      return currentOptions;
    },
  },
};
</script>

<style>
</style>