<template>
  <div class="overflow-hidden overflow-x-auto">
    <div class="py-2 pr-2 min-w-[800px]">
      <div
        :class="`grid `"
        :style="
          gridTemplate
            ? `grid-template-columns: ${gridTemplate}`
            : `grid-template-columns: 250px repeat(${numberOfColumns}, minmax(0, 1fr))`
        "
      >
        <!-- RENDER FIRST EMPTY SPACE -->
        <div class="customBordered flex items-center p-2" v-if="title">{{ title }}</div>
        <div class="" v-else></div>
        <!-- RENDER QUESTIONS -->
        <div class="customBordered" v-for="field in fields" :key="field.id">
          <div class="p-2">
            {{ field.label }}
            <div class="formulate-input-help formulate-input-help--before">
              {{ field.help }}
            </div>
          </div>
        </div>

        <template v-for="task in tasks">
          <div class="customBordered p-3 font-bold" :key="'task-' + task.id">{{ task.label }}</div>

          <div
            class="customBordered flex items-center px-2 py-2"
            v-for="field in fields"
            :key="'task-' + task.id + '-field-' + field.id"
          >
            <div class="pt-1 px-1 w-full">
              <FormulateInput
                error-behavior="blur"
                :disabled="context.attributes.disabled"
                class="w-full"
                v-bind="field"
                v-model="localData['task-' + task.id + '-field-' + field.id]"
                @input="(e) => handleChange('task-' + task.id + '-field-' + field.id, e)"
                ignored
                label=""
                help=""
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localData: {},
    };
  },
  methods: {
    handleChange: _.debounce(function (id, value) {
      let currentValue = value;

      this.context.model = { ...this.context.model, [id]: currentValue };
    }, 50),
    getValue(id) {
      //  :name="context.name + '-task-' + task.id + '-field-' + field.id"

      // :value="getValue('task-' + task.id + '-field-' + field.id)"
      //           @input="(e) => handleChange('task-' + task.id + '-field-' + field.id, e)"
      return this.context.model?.[id] || "";
    },
  },
  computed: {
    gridTemplate() {
      return this.context?.attributes?.gridTemplate;
    },
    title() {
      return this.context?.attributes?.title;
    },
    modelData() {
      return JSON.stringify(this.context.model);
    },
    fields() {
      return this.context.attributes?.fields.map((field) => {
        return {
          ...field,
          options: field.options
            ? field.options.map((opt) => {
                return { ...opt, label: this.externalTranslate(opt.label) };
              })
            : undefined,
        };
      });
    },
    tasks() {
      return this.context.attributes?.tasks;
    },

    numberOfColumns() {
      return this.fields?.length || 1;
    },
  },
  mounted() {
    this.localData = this.context.model || {};
  },
  watch: {
    modelData(newValue, oldValue) {
      this.localData = JSON.parse(newValue) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.customBordered {
  box-shadow: 2px 0 0 0 #888, 0 2px 0 0 #888, 2px 2px 0 0 #888,
    /* Just to fix the corner */ 2px 0 0 0 #888 inset, 0 2px 0 0 #888 inset;
}
</style>