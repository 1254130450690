<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { loveApi } from "./api/Api";
import configurations from "./utils/configurations";

export default {
  components: {},
  name: "App",
  data() {
    return {
      opened: true,
    };
  },
  computed: {
    token() {
      return localStorage.getItem(configurations.sessionFlag);
    },
  },
  methods: {
    ...mapActions(["checkLogin"]),
  },
  created() {
    this.checkLogin();
  },
  watch: {
    token: {
      immediate: true,
      deep: true,
      handler(newValue) {
        loveApi.defaults.headers.common["Authorization"] = `Token session="${newValue}"`;
      },
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>
