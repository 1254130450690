import axios from "axios";

const logIn = async (email, password) => {
  return new Promise((resolve, reject) => {
    const authData = {
      email: email,
      password: password,
    };
    let sessionPath = process.env.VUE_APP_API + "/v2/accounts/login";

    axios
      .post(sessionPath, authData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject("Invalid credentials");
      });
  });
};
const checkSession = async (token) => {
  return new Promise((resolve, reject) => {
    let sessionPath = process.env.VUE_APP_API + "/v2/accounts/session_info";

    axios
      .get(sessionPath, {
        headers: {
          Authorization: `Token session="${token}"`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject("Invalid session");
      });
  });
};

export { logIn, checkSession };
