<template>
  <div
    class="relative"
    :class="`formulate-input-element password-field formulate-input-element--${context.type}`"
    :data-type="context.type"
  >
    <input
      class="pr-8"
      :type="showPassword ? 'text' : 'password'"
      v-model="context.model"
      v-bind="context.attributes"
      autocomplete="no"
      @blur="context.blurHandler"
    />
    <button
      type="button"
      @click="showPassword = !showPassword"
      class="icon-button absolute top-2 right-2"
    >
      <span class="visually-hidden">{showPassword ? 'Hide password' : 'Show password'}</span>
      <div class="icon-eye">
        <v-icon color="primary" small v-show="!showPassword"> fa-regular fa-eye</v-icon>
        <v-icon color="primary" small v-show="showPassword">fa-regular fa-eye-slash</v-icon>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "CustomPassword",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>

<style scoped>
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>