export default {
  breadcrumb: {
    home: {
      en: "Home",
      es: "Inicio",
    },
    organizations: {
      en: "Organizations",
      es: "Organizaciones",
    },
    profile: {
      en: "Profile",
      es: "Perfil",
    },
    projects: {
      en: "Projects of",
      es: "Proyectos de",
    },
    contact: {
      en: "Contact us",
      es: "Contáctanos",
    },
    about: {
      en: "About us",
      es: "Acerca de nosotros",
    },
    manual: {
      en: "Handbook",
      es: "Handbook",
    },
  },
  header: {
    userMenu: {
      signOut: {
        es: "Cerrar sesión",
        en: "Sign out",
      },
      viewProfile: {
        en: "View profile",
        es: "Ver perfil",
      },
    },
    language: {
      es: "Idioma",
      en: "Language",
    },
    signIn: {
      es: "Iniciar sesión",
      en: "Sign in",
    },
    signUp: {
      es: "Registrarse",
      en: "Sign up",
    },
  },
  login: {
    signInMessage: {
      es: "Iniciar sesión",
      en: "Sign in",
    },
    password: {
      es: "Contraseña",
      en: "Password",
    },
    forgot: {
      en: "Forgot password?",
      es: "¿Olvidaste tu contraseña?",
    },
    createAccount: {
      en: "Create an account",
      es: "Crear cuenta",
    },
    goBack: {
      en: "Go back",
      es: "Ir atrás",
    },
  },
  homeView: {
    funding: {
      en: "This work has been done thanks to Grant PI21/01564 from the Fondo de Investigación en Salud Instituto Carlos III (ISCIII) de Madrid, and it is part of the program LE-IHD  that received funding from the European Union’s Horizon 2020 Research and Innovation program under a Marie Skłodowska-Curie Grant (MSCA-IF-EF-ST #894990) to María Ximena Rojas-Reyes.",
      es: "This work has been done thanks to Grant PI21/01564 from the Fondo de Investigación en Salud Instituto Carlos III (ISCIII) de Madrid, and it is part of the program LE-IHD  that received funding from the European Union’s Horizon 2020 Research and Innovation program under a Marie Skłodowska-Curie Grant (MSCA-IF-EF-ST #894990) to María Ximena Rojas-Reyes.",
    },
    logInBtn: {
      en: "Log in / Sign in",
      es: "Iniciar sesión",
    },
    viewExample: {
      en: "View example",
      es: "Ver ejemplo",
    },
    goToManual: {
      en: "Go to Handbook",
      es: "Go to Handbook",
    },
    pills: {
      first: {
        title: {
          en: "Living Evidence to inform health decisions framework",
          es: "Living Evidence to inform health decisions framework",
        },

        description: {
          en: "This tool is based on a framework developed to guide groups or institutions conducting evidence synthesis to inform clinical or health policy decisions, to use appropriate criteria for defining which clinical problems (structured into clinical questions) benefit from a constant review of the new evidence, the frequency with which these processes should be carried out, and whether to incorporate the new evidence synthesis and conclusions in the knowledge transfer products this evidence supports (e.g. recommendations in clinical practice guidelines, health technology assessment reports or structured evidence summaries).",
          es: "This tool is based on a framework developed to guide groups or institutions conducting evidence synthesis to inform clinical or health policy decisions, to use appropriate criteria for defining which clinical problems (structured into clinical questions) benefit from a constant review of the new evidence, the frequency with which these processes should be carried out, and whether to incorporate the new evidence synthesis and conclusions in the knowledge transfer products this evidence supports (e.g. recommendations in clinical practice guidelines, health technology assessment reports or structured evidence summaries).",
        },
      },
    },
    descriptionData: {
      title: {
        en: "The framework",
        es: "The framework",
      },
      description: {
        en: "The tool has three main sections based on the moment in which they must be applied during the living evidence synthesis process (e.g. defining the problem, planning the evidence synthesis, and the evidence monitoring). Each section poses a series of questions that guide users in defining each step of the process. There are some stages that are cyclical, such as the periodic evidence monitoring, therefore can be worked on as many times as necessary during the living evidence synthesis process. A set of instructions guides users step by step and the handbook available alongside the tool gives advice about each particular subject or consideration.",
        es: "The tool has three main sections based on the moment in which they must be applied during the living evidence synthesis process (e.g. defining the problem, planning the evidence synthesis, and the evidence monitoring). Each section poses a series of questions that guide users in defining each step of the process. There are some stages that are cyclical, such as the periodic evidence monitoring, therefore can be worked on as many times as necessary during the living evidence synthesis process. A set of instructions guides users step by step and the handbook available alongside the tool gives advice about each particular subject or consideration.",
      },
    },
  },
  projectsView: {
    recentlyOpen: {
      en: "Recently opened",
      es: "Recientemente abierto",
    },
    projects: {
      en: "Projects",
      es: "Proyectos",
    },
  },
  projectForm: {
    projectName: {
      en: "Name of project",
      es: "Nombre del proyecto",
    },
    projectDescription: {
      en: "Description",
      es: "Descripción",
    },
    members: {
      en: "Members",
      es: "Miembros",
    },
    addMember: {
      en: "Add member",
      es: "Añadir miembro",
    },
    errorMembers: {
      en: "Please, check the members",
      es: "Por favor, verifique los miembros",
    },
  },
  misc: {
    finish: {
      en: "Save",
      es: "Guardar",
    },
    empty: {
      en: "Empty",
      es: "Vacío",
    },
    question: {
      en: "PICO Question #",
      es: "Pregunta PICO #",
    },
    addLogo: {
      en: "Add a logo to your organization",
      es: "Añade un logo a tu organización",
    },
    projectSetup: {
      en: "Setup",
      es: "Configuración",
    },
    forgetRecentProject: {
      en: "Forget recently opened project",
      es: "Olvidar proyecto recientemente abierto",
    },
    forgetProjectConfirmation: {
      es: "¿Esta seguro de que quieres olvidar este proyecto? Solo se eliminará del listado de proyectos recientes",
      en: "¿Are you sure you want to forget this project? It will only be removed from the recent projects list",
    },
    showInfoOrganization: {
      es: "Mostrar información de la organización",
      en: "Show organization information",
    },
    showInfoProject: {
      es: "Mostrar información del proyecto",
      en: "Show project information",
    },
    deleteProject: {
      en: "Delete project",
      es: "Eliminar proyecto",
    },
    deleteOrganization: {
      en: "Delete organization",
      es: "Eliminar organización",
    },
    settingProject: {
      es: "Configuración del proyecto",
      en: "Project's settings",
    },
    underConstruction: {
      en: "Sorry, this page is under construction",
      es: "Lo siento, esta página se encuentra en construcción",
    },
    underConstructionBtn: {
      en: "Under construction",
    },
    organization: {
      en: "Organization",
      es: "Organización",
    },
    project: {
      en: "Project",
      es: "Proyecto",
    },
    addAn: {
      en: "Add an ",
      es: "Añadir ",
    },
    addA: {
      en: "Add a ",
      es: "Añadir ",
    },
    yes: {
      en: "Yes",
      es: "Si",
    },
    no: {
      en: "No",
      es: "No",
    },
    specify: {
      en: "Specify",
      es: "Especificar",
    },
    contactErrorFeedback: {
      en: "Organization deleted successfully",
      es: "Organización eliminada correctamente",
    },
    contactFeedback: {
      en: "Thanks! We will get back to you shortly",
      es: "Gracias! Te responderemos a la brevedad",
    },
    deleteOrganizations: {
      es: "¿Está seguro de eliminar esta organización?",
      en: "Are you sure you want to delete this organization?",
    },
    deleteOrganizationsFeedback: {
      en: "Organization deleted successfully",
      es: "Organización eliminada correctamente",
    },
    deleteProjectConfirmation: {
      es: "¿Está seguro de eliminar este proyecto?",
      en: "Are you sure you want to delete this project?",
    },
    createOrganization: {
      en: "Create a new organization",
      es: "Crear nueva organización",
    },
    updateOrganization: {
      en: "Update organization",
      es: "Actualizar organización",
    },
    save: {
      en: "Save",
      es: "Guardar",
    },
    send: {
      en: "Send",
      es: "Enviar",
    },
    cancel: {
      en: "Cancel",
      es: "Cancelar",
    },
    opened: {
      en: "Opened",
      es: "Abierto",
    },
    open: {
      en: "Open",
      es: "Abrir",
    },
    createProject: {
      en: "New project",
      es: "Nuevo proyecto",
    },
    updateProject: {
      en: "Update project",
      es: "Actualizar proyecto",
    },
    manager: {
      en: "Manager",
      es: "Gestor",
    },
    editor: {
      en: "Editor",
      es: "Editor",
    },
    presentation: {
      en: "Presentation",
      es: "Presentación",
    },
    instructions: {
      en: "Instructions",
      es: "Instrucciones",
    },
    anotherOption: {
      en: "Please type another option here",
      es: "Por favor escriba otra opción aquí",
    },
    selectAOption: {
      en: "Please select a option",
      es: "Por favor seleccione una opción",
    },
    population: {
      en: "Population",
      es: "Población",
    },
    intervention: {
      en: "Intervention / exposure",
      es: "Intervención / exposición",
    },
    comparator: {
      en: "Comparator",
      es: "Comparador",
    },
    outcomes: {
      en: "Main outcomes",
      es: "Desenlaces principales",
    },
    message: {
      en: "Message",
      es: "Mensaje",
    },
    name: {
      en: "Name",
      es: "Nombre",
    },
    logo: {
      en: "Logo",
      es: "Logo",
    },
    description: {
      en: "Description",
      es: "Descripción",
    },

    exportSection: {
      en: "Export",
      es: "Exportar",
    },
    goTo: {
      en: "Go to",
      es: "Go to",
    },

    goToSectionPico: {
      en: "You must fill in the PICO questions in the summary section to continue",
      es: "Debes completar las preguntas PICO en el resumen de sección para continuar",
    },
    clickHere: {
      en: "Click here",
      es: "Click aquí",
    },
    contactWelcome: {
      en: "Hello! Let us know how we can help you!",
      es: "Hello! Let us know how we can help you!",
    },
    goMonitoringSelection: {
      en: "go to monitoring selection",
    },
    createMonitoring: {
      en: "Create monitoring",
    },
    dateMonitoring: {
      en: "Monitoring date",
    },
    monitoringList: {
      en: "Monitoring list",
    },
    newMonitoring: {
      en: "New monitoring",
    },
    monitoringNo: {
      en: "Monitoring N°",
    },
    options: {
      en: "Options",
    },
    forQuestion: {
      en: "for the question",
    },
    deleteMonitoringConfirm: {
      en: "Are you sure you want to remove monitoring?",
    },
    noQuestions: {
      en: "No P.I.C.O questions",
      es: "No P.I.C.O questions",
    },
    monitoringPrisma: {
      en: "Monitoring Prisma (XLSX)",
      es: "Monitoring Prisma (XLSX)",
    },
    projectPdf: {
      en: "Project (PDF)",
      es: "Project (PDF)",
    },
    protocol: {
      en: "Protocol (DOCX)",
      es: "Protocol (DOCX)",
    },
    copyInitial: {
      en: "Copy default value",
      es: "Copy default value",
    },
    removeContent: {
      en: "Remove the content of the textarea to copy the placeholder",
      es: "Remove the content of the textarea to copy the placeholder",
    },
    seeMore: {
      en: "See more",
      es: "See more",
    },
    confirmNameOrganization: {
      en: "Confirm the name of the organization",
      es: "Confirm the name of the organization",
    },
    invalidConfirm: {
      en: "the confirmation is not valid",
      es: "the confirmation is not valid",
    },
  },
  validations: {
    projectNameLength: {
      en: "Name must be less than 100 characters",
    },
    requireSelectPico: {
      en: "Please select a pico question in the planning",
      es: "Please select a pico question in the planning",
    },
  },
};
