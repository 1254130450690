import Vue from "vue";
import Vuex from "vuex";
import { checkSession, logIn } from "@/api/authentication";
import router from "@/router";
import moment from "moment";
import configurations from "@/utils/configurations";
import { loveApi } from "@/api/Api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    verifyLogin: false,
    currentLanguage: configurations.defaultLang,
    isLogin: false,
    user: {},
  },
  getters: {
    isLogin(state) {
      return state.isLogin;
    },
    token() {
      return localStorage.getItem(configurations.sessionFlag);
    },
    userName(state) {
      return state.user.name + " " + state.user.surname;
    },
    userEmail(state) {
      return state.user.email;
    },
    isAdmin(state) {
      return state.user?.roles?.some((role) => role === "admin");
    },
  },
  mutations: {
    changeLanguage(state, language) {
      state.currentLanguage = language;
    },
    changeAuthState(state, authState) {
      if (!authState) {
        // HANDLE LOGOUT
        localStorage.removeItem(configurations.isAuthFlag);
        localStorage.removeItem(configurations.sessionFlag);
        router.replace("/").catch(() => { });
      }
      state.verifyLogin = authState ? true : false;
      state.user = authState ? authState : {};
      state.isLogin = authState ? true : false;
    },
  },
  actions: {
    // Check if the user has a valid session
    async checkLogin({ commit }) {
      const hasAuthFlag = localStorage.getItem(configurations.isAuthFlag);
      const hasSessionFlag = localStorage.getItem(configurations.sessionFlag);
      if (hasAuthFlag && hasSessionFlag) {
        checkSession(hasSessionFlag)
          .then((e) => {
            commit("changeAuthState", e);
          })
          .catch((e) => {
            console.error(e);
            commit("changeAuthState", null);
          });
      }
    },
    // remove all existing auth states
    async handleLogout({ commit }) {
      commit("changeAuthState", null);
    },
    // call the api method to log in
    async handleLogin(context, loginData) {
      return logIn(loginData.email, loginData.password)
        .then(async (result) => {
          localStorage.setItem(configurations.isAuthFlag, true);
          localStorage.setItem(configurations.sessionFlag, result.session_token);
          loveApi.defaults.headers.common[
            "Authorization"
          ] = `Token session="${result.session_token}"`;
          context.commit("changeAuthState", result);
        })
        .catch((reason) => {
          Vue.$toast.error(reason);
        });
    },
    // Change the language of the application
    changeLanguage(context, language) {
      context.commit("changeLanguage", language);
    },

    // Recently projects
    setRecently(context, recently) {
      const currentRecently = localStorage.getItem(configurations.openedProjectsFlag);

      const restOfProjects = { ...JSON.parse(currentRecently), [recently.id]: moment() };

      localStorage.setItem(configurations.openedProjectsFlag, JSON.stringify(restOfProjects));
    },
    getRecentyleProjects(context) {
      const lastOpened = localStorage.getItem(configurations.openedProjectsFlag);
      const formattedOpened = lastOpened ? JSON.parse(lastOpened) : {};

      return Object.entries(formattedOpened)
        .map(([key, value]) => ({
          id: key,
          date: moment(value).locale(context.state.currentLanguage).fromNow(),
          moment: moment(value),
        }))
        .sort((a, b) => b.moment - a.moment);
    },
    removeRecentlyProject(context, id) {
      const lastOpened = localStorage.getItem(configurations.openedProjectsFlag);
      const formattedOpened = lastOpened ? JSON.parse(lastOpened) : {};
      delete formattedOpened[id];
      localStorage.setItem(configurations.openedProjectsFlag, JSON.stringify(formattedOpened));
    },
  },
  modules: {},
});
