import getTranslation from "@/utils/getTranslation";
import translations from "@/utils/translations";
import _ from "lodash";

const handleLocalTranslate = (key) => {
  return getTranslation(_.get(translations, key));
};

export { handleLocalTranslate };

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        translate: function (key) {
          return handleLocalTranslate(key);
        },
        externalTranslate: function (value) {
          return getTranslation(value);
        },
      },
    });
  },
};
