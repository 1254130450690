var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('div',{class:`grid `,style:(`grid-template-columns: 130px repeat(${_vm.numberOfColumns}, minmax(0, 1fr))`)},[_c('div',{}),_vm._l((_vm.questions),function(question,i){return _c('div',{key:i,staticClass:"customBordered"},[_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(question.label)+" ")])])}),_c('div',{}),_vm._l((_vm.questions),function(question,i){return _c('div',{key:'opt-' + i,staticClass:"customBordered"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"py-5 h-full w-1/2 flex justify-center items-center customBordered"},[_c('v-icon',{attrs:{"left":"","color":"green"}},[_vm._v("fa-regular fa-circle-check")]),_vm._v(" "+_vm._s(_vm.translate("misc.yes"))+" ")],1),_c('div',{staticClass:"py-5 h-full w-1/2 flex justify-center items-center"},[_c('v-icon',{attrs:{"left":"","color":"red"}},[_vm._v("fa-regular fa-circle-xmark")]),_vm._v(" "+_vm._s(_vm.translate("misc.no"))+" ")],1)])])}),_vm._l((_vm.currentPicoQuestions),function(pico,p){return [_c('div',{key:'pico-' + p,staticClass:"customBordered p-3 font-bold gap-2 flex items-center"},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getResponses(pico)),expression:"getResponses(pico)"}],attrs:{"color":"primary"}},[_vm._v(" fas fa-info-circle ")]),_vm._v(" PICO "+_vm._s(p + 1)+" ")],1),_vm._l((_vm.questions),function(question,i){return _c('div',{key:'pico-' + p + '-response-' + i,staticClass:"customBordered"},[_c('div',{staticClass:"h-full flex"},[_c('div',{staticClass:"duration-150 cursor-pointer h-full w-1/2 flex justify-center items-center customBordered",class:{
              ' hover:bg-green-200': !_vm.context.attributes.disabled,
              'cursor-not-allowed': _vm.context.attributes.disabled,
              'bg-green-200': _vm.getCurrentValue(pico, question) === true,
            },attrs:{"disabled":_vm.context.attributes.disabled},on:{"click":function($event){!_vm.context.attributes.disabled && _vm.handleChange(pico, question, true)}}}),_c('div',{staticClass:"cursor-pointer duration-150 h-full w-1/2 flex justify-center items-center customBordered",class:{
              'hover:bg-red-200': !_vm.context.attributes.disabled,
              'cursor-not-allowed': _vm.context.attributes.disabled,
              'bg-red-200': _vm.getCurrentValue(pico, question) === false,
            },attrs:{"disabled":_vm.context.attributes.disabled},on:{"click":function($event){!_vm.context.attributes.disabled && _vm.handleChange(pico, question, false)}}})])])})]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }