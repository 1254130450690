import store from "@/store";
import axios from "axios";
import moment from "moment";

export const loveApi = axios.create({
  baseURL: process.env.VUE_APP_API + "/le/",
});
export default class Api {
  static host = process.env.VUE_APP_API + "/le/";
  static firebaseHost = process.env.VUE_APP_FIREBASE_API;
  static getHeaders() {
    let authToken = store.getters.token;
    return {
      Authorization: authToken ? `Token session="${authToken}"` : undefined,
    };
  }

  static put(path, data) {
    return loveApi.put(path, data);
  }

  static get(path, data) {
    return loveApi
      .get(path, {
        params: data,
      })
      .then((e) => e.data);
  }

  static post(path, data) {
    return loveApi.post(path, data);
  }

  static delete(path) {
    return loveApi.delete(path);
  }
  static downloadPrisma(path, data) {
    return fetch(Api.firebaseHost + path, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.getHeaders(),
      // mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "Monitoring Prisma.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }
  static downloadProtocol(path, data) {
    return fetch(Api.firebaseHost + path, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.getHeaders(),
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `Protocol_${moment().format("YYYYMMDD_HHmm")}.docx`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
  }
}
