import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueFormulate from "@braid/vue-formulate";
import VueSweetalert2 from "vue-sweetalert2";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./firebase-config";
// Custom styles
import "@/assets/tailwind.css";
import "@/assets/formulateStyle.scss";
import "@/assets/main.scss";
import "sweetalert2/dist/sweetalert2.min.css";
// Toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// Custom form elements
import picoInut from "@/components/cutomFormsElements/picoInput";
import CustomBoxList from "@/components/cutomFormsElements/custom-boxlist";
import ToggablePassword from "@/components/cutomFormsElements/ToggablePassword";
import Step8 from "@/components/cutomFormsElements/step8";
import PicoSelector from "@/components/cutomFormsElements/picoSelector";

import TaskTable from "@/components/cutomFormsElements/taskTable";
import MembersInput from "@/components/cutomFormsElements/membersInput";
import managersInput from "@/components/cutomFormsElements/managersInput";
import inputWithInitial from "@/components/cutomFormsElements/inputWithInitial";
import translate from "./mixins/translate";
import misc from "./mixins/misc";
import VTooltip from "v-tooltip";
import { es, en } from "@braid/vue-formulate-i18n";
import configurations from "./utils/configurations";
Vue.config.productionTip = false;

// Register custom form elements
Vue.component("picoInut", picoInut);
Vue.component("ToggablePassword", ToggablePassword);
Vue.component("membersInput", MembersInput);
Vue.component("managersInput", managersInput);
Vue.component("step-8", Step8);
Vue.component("pico-selector", PicoSelector);
Vue.component("task-table", TaskTable);
Vue.component("custom-boxlist", CustomBoxList);
Vue.component("input-with-initial", inputWithInitial);

// Add vue formulate
Vue.use(VueFormulate, {
  plugins: [es, en],
  locale: store.state.locale || configurations.defaultLang,
  library: {
    "custom-boxlist": {
      component: "custom-boxlist",
    },
    "input-with-initial": {
      component: "input-with-initial",
    },
    "task-table": {
      component: "task-table",
    },
    "pico-selector": {
      component: "pico-selector",
      slotProps: {
        component: ["getFrom"],
      },
    },
    "step-8": {
      classification: "text",
      component: "step-8",
      slotProps: {
        component: ["questions", "getFrom"],
      },
    },
    pico: {
      classification: "text",
      component: "picoInut",
    },
    members: {
      classification: "text",
      component: "membersInput",
    },
    managers: {
      classification: "text",
      component: "managersInput",
    },
    "toggable-password": {
      classification: "text",
      component: "ToggablePassword",
    },
  },
});
Vue.use(VTooltip);
Vue.use(translate);
Vue.use(misc);
Vue.use(VueSweetalert2);
Vue.use(Toast, {
  position: "bottom-center",
  // You can set your default options here
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const analytics = getAnalytics(app);
  },
}).$mount("#app");
