<template>
  <div class="py-2">
    <div
      :class="`grid `"
      :style="`grid-template-columns: 130px repeat(${numberOfColumns}, minmax(0, 1fr))`"
    >
      <!-- RENDER FIRST EMPTY SPACE -->
      <div class=""></div>
      <!-- RENDER QUESTIONS -->
      <div class="customBordered" v-for="(question, i) in questions" :key="i">
        <div class="p-2">
          {{ question.label }}
        </div>
      </div>
      <!-- RENDER SECOND EMPTY SPACE -->
      <div class=""></div>
      <!-- RENDER OPT (YES/NOT) -->
      <div class="customBordered" v-for="(question, i) in questions" :key="'opt-' + i">
        <div class="flex">
          <div class="py-5 h-full w-1/2 flex justify-center items-center customBordered">
            <v-icon left color="green">fa-regular fa-circle-check</v-icon>
            {{ translate("misc.yes") }}
          </div>
          <div class="py-5 h-full w-1/2 flex justify-center items-center">
            <v-icon left color="red">fa-regular fa-circle-xmark</v-icon>
            {{ translate("misc.no") }}
          </div>
        </div>
      </div>
      <template v-for="(pico, p) in currentPicoQuestions">
        <div class="customBordered p-3 font-bold gap-2 flex items-center" :key="'pico-' + p">
          <v-icon color="primary" v-tooltip="getResponses(pico)"> fas fa-info-circle </v-icon> PICO
          {{ p + 1 }}
        </div>

        <div
          class="customBordered"
          v-for="(question, i) in questions"
          :key="'pico-' + p + '-response-' + i"
        >
          <div class="h-full flex">
            <div
              :disabled="context.attributes.disabled"
              @click="!context.attributes.disabled && handleChange(pico, question, true)"
              class="
                duration-150
                cursor-pointer
                h-full
                w-1/2
                flex
                justify-center
                items-center
                customBordered
              "
              :class="{
                ' hover:bg-green-200': !context.attributes.disabled,
                'cursor-not-allowed': context.attributes.disabled,
                'bg-green-200': getCurrentValue(pico, question) === true,
              }"
            ></div>
            <div
              :disabled="context.attributes.disabled"
              @click="!context.attributes.disabled && handleChange(pico, question, false)"
              class="
                cursor-pointer
                duration-150
                h-full
                w-1/2
                flex
                justify-center
                items-center
                customBordered
              "
              :class="{
                'hover:bg-red-200': !context.attributes.disabled,
                'cursor-not-allowed': context.attributes.disabled,
                'bg-red-200': getCurrentValue(pico, question) === false,
              }"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getResponses(pico) {
      const response = pico["pico-question"];

      return {
        html: true,
        content: `
        <p>${this.translate("misc.population")}: ${response.P ? response.P : ""}</p>
        <p>${this.translate("misc.intervention")}: ${response.I ? response.I : ""}</p>
        <p>${this.translate("misc.comparator")}: ${response.C ? response.C : ""}</p>
        <p>${this.translate("misc.outcomes")}: ${response.O ? response.O : ""}</p>`,
      };
    },
    getCurrentValue(pico, question) {
      const idPico = pico[Object.keys(pico)[0]]?.idPico;
      const idQuestion = question.id;

      return this.context.model?.[idPico]?.[idQuestion];
    },
    handleChange(pico, question, value) {
      const idPico = pico[Object.keys(pico)[0]]?.idPico;
      const idQuestion = question.id;
      const newStatus = this.getCurrentValue(pico, question) !== value ? value : null;
      const newValue = {
        ...this.context.model,
        [idPico]: {
          ...this.context.model[idPico],
          [idQuestion]: newStatus,
        },
      };
      {
        idPico, idQuestion, value;
      }
      this.context.model = newValue;
    },
    getValue(element) {
      return this.context.model?.[element.id] || "";
    },
  },
  computed: {
    currentPicoQuestions() {
      const values = this.context.attributes?.currentValues?.[this.getFrom] || [];

      return [...values];
    },
    getFrom() {
      return this.context?.slotProps?.component?.getFrom;
    },
    numberOfColumns() {
      return this.questions?.length || 1;
    },
    questions() {
      const questions = this.context?.slotProps?.component?.questions || [];
      return questions;
    },
  },
};
</script>

<style lang="scss" scoped>
.customBordered {
  box-shadow: 2px 0 0 0 #888, 0 2px 0 0 #888, 2px 2px 0 0 #888,
    /* Just to fix the corner */ 2px 0 0 0 #888 inset, 0 2px 0 0 #888 inset;
}
</style>