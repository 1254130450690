<template>
  <div>
    <v-row dense class="mb-2">
      <v-col
        cols="12"
        v-for="element in elementsPico"
        :key="element.id"
        class="flex gap-5 items-center"
      >
        <label :for="element.id" class="w-1/5 text-right">{{ element.label }}</label>
        <input
          :disabled="disabled"
          :value="getValue(element)"
          @input="(value) => handleChange(value, element)"
          :id="element.id"
          type="text"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      values: {
        idPico: null,
        P: "",
        I: "",
        C: "",
        O: "",
      },
    };
  },
  methods: {
    handleChange({ target: { value } }, element) {
      if (typeof this.context.model == "string") {
        this.context.model = {};
      }
      this.context.model = { ...this.context.model, [element.id]: value };
    },
    getValue(element) {
      return this.context.model?.[element.id] || "";
    },
  },
  computed: {
    disabled() {
      return this.context.attributes.disabled;
    },
    existModel() {
      return this.context.model;
    },
    localeValues() {
      return JSON.stringify(this.values);
    },
    initialValue() {
      return JSON.stringify(this.context.model);
    },

    elementsPico() {
      return [
        {
          id: "P",
          label: this.translate("misc.population"),
        },
        {
          id: "I",
          label: this.translate("misc.intervention"),
        },
        {
          id: "C",
          label: this.translate("misc.comparator"),
        },
        {
          id: "O",
          label: this.translate("misc.outcomes"),
        },
      ];
    },
  },

  mounted() {
    if (!this.context.model.idPico) {
      this.context.model = { ...this.context.model, idPico: uuidv4() };
    }
  },
};
</script>

<style>
</style>