<template>
  <div>
    <v-radio-group
      :disabled="disabled"
      hide-details
      :value="baseValue"
      @change="handleChangeBase"
      v-if="subtype === 'radio'"
    >
      <div v-for="option in options" :key="option.value" class="mb-2">
        <v-radio :label="externalTranslate(option.label)" :value="option.value"></v-radio>
        <div class="ml-8" v-if="option.nested && isActivate(option)">
          <FormulateInput
            :disabled="disabled"
            v-bind="handleNested(option.nested)"
            ignored
            :value="getNestedValue(option)"
            @input="(e) => handleNestedChange(e, option)"
            #default="{ index }"
          >
            <v-row dense>
              <v-col
                cols="12"
                v-for="(itemChildren, elementIndex) in handleNested(option.nested).children"
                :key="itemChildren.name"
              >
                <p
                  class="font-bold mb-0"
                  v-if="handleNested(option.nested).showIndex && elementIndex == 0"
                >
                  {{ externalTranslate(handleNested(option.nested).indexName) }} {{ index + 1 }}
                </p>

                <FormulateInput :disabled="disabled" v-bind="itemChildren"> </FormulateInput>
              </v-col>
            </v-row>
          </FormulateInput>
        </div>
      </div>
    </v-radio-group>

    <div v-if="subtype === 'checkbox'">
      <div v-for="option in options" :key="option.value" class="mb-2">
        <v-checkbox
          :disabled="disabled"
          multiple
          :input-value="baseValue"
          @change="handleChangeBase"
          hide-details
          :label="externalTranslate(option.label)"
          :value="option.value"
        ></v-checkbox>
        <div class="ml-8" v-if="option.nested && isActivate(option)">
          <FormulateInput
            v-bind="handleNested(option.nested)"
            ignored
            :value="getNestedValue(option)"
            @input="(e) => handleNestedChange(e, option)"
            #default="{ index }"
          >
            <v-row dense>
              <v-col
                cols="12"
                v-for="(itemChildren, elementIndex) in handleNested(option.nested).children"
                :key="itemChildren.name"
              >
                <p
                  class="font-bold mb-0"
                  v-if="handleNested(option.nested).showIndex && elementIndex == 0"
                >
                  {{ externalTranslate(handleNested(option.nested).indexName) }} {{ index + 1 }}
                </p>

                <FormulateInput :disabled="disabled" v-bind="itemChildren"> </FormulateInput>
              </v-col>
            </v-row>
          </FormulateInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleNested(nested) {
      return {
        ...nested,
        disabled: this.disabled,
        repeatable: !this.disabled && nested?.repeatable,
        label: nested?.label
          ? this.externalTranslate(nested?.label)
          : this.translate("misc.specify"),
        addLabel: nested?.addLabel ? this.externalTranslate(nested?.addLabel) : undefined,
        help: nested?.help ? this.externalTranslate(nested?.help) : "",
        options:
          nested?.options?.map((option) => {
            return { ...option, label: this.externalTranslate(option.label) };
          }) || [],
      };
    },

    getNestedValue(opt) {
      return this.context.model?.["nested-" + opt.value];
    },
    handleNestedChange: _.debounce(function (value, opt) {
      this.context.model = { ...this.context.model, ["nested-" + opt.value]: value };
    }, 200),
    handleChangeBase(element) {
      this.context.model = {
        ...this.context.model,
        base: element,
      };
    },
    isActivate(element) {
      return Array.isArray(this.baseValue)
        ? this.baseValue.includes(element.value)
        : element.value == this.baseValue;
    },
  },
  computed: {
    disabled() {
      return this.context.attributes.disabled;
    },
    baseValue() {
      return this.context.model?.base || "";
    },
    options() {
      return this.context.options || [];
    },
    subtype() {
      return this.context.attributes?.subtype || "radio";
    },
  },
};
</script>

<style>
</style>