import store from "@/store";
import configurations from "@/utils/configurations";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // ? UNSECURED routes
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    meta: {
      // hideOnLogin: true,
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "about",

    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/manual",
    name: "manual",

    component: () => import(/* webpackChunkName: "manual" */ "../views/Manual.vue"),
  },

  {
    path: "/contact",
    name: "contact",

    component: () => import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      hideOnLogin: true,
    },
    component: () => import(/* webpackChunkName: "auth" */ "../views/Login.vue"),
  },

  // ? SECURED ROUTES
  {
    path: "/profile",
    name: "profile",
    meta: {
      secure: true,
    },
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
  },
  {
    path: "/organizations",
    name: "organizations",
    meta: {
      secure: true,
    },
    component: () => import(/* webpackChunkName: "organizations" */ "../views/Organizations.vue"),
  },
  {
    path: "/organizations/:idOrganization/projects",
    name: "projects",
    meta: {
      secure: true,
    },
    component: () => import(/* webpackChunkName: "organizations" */ "../views/ProjectsList.vue"),
  },
  {
    path: "/organizations/:idOrganization/projects/:idProject",
    name: "projectDetails",
    meta: {
      secure: true,
    },
    component: () => import(/* webpackChunkName: "framework" */ "../views/Project.vue"),
  },
  {
    path: "/example/:idProject",
    name: "example-project",

    component: () => import(/* webpackChunkName: "framework" */ "../views/Project.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state.isLogin;
  const hasAuthFlag = localStorage.getItem(configurations.isAuthFlag);

  // ? If the user is not authenticated and tries to access a secured route, redirect to the login page
  if (to.name !== "login" && !isAuthenticated && !hasAuthFlag && to.meta.secure) {
    next({ name: "login" });
  }
  // ? If user is loged, remove some routes
  if (to.meta.hideOnLogin && (isAuthenticated || hasAuthFlag)) {
    next("/organizations");
  } else next();
});

export default router;
