export default {
  openedProjectsFlag: "3bc2fb95-2bbd-475d-aeac-df9074b4b296",
  isAuthFlag: "isAuth",
  sessionFlag: "LE_S",
  defaultRolProject: "manager",
  roles: {
    manager: "misc.manager",
    editor: "misc.editor",
  },
  defaultLang: "en",
};
