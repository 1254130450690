/** @type {import('tailwindcss').Config} */

const colors = {
  primary: "#339999",
  primaryHover: "#D6E5E5",
  primaryBackground: "#EEF9F9",
  orange: "#E07907",
  danger: "#E53935",
  "txt-gray": "#333333",
  "light-grey": "#C9CCCE",
  breadcrumb: "#FFA400",
  "breadcrumb-hover": "#E07907",
  footer: "#E07907",
  "sidebar-background": "#339999",
  "sidebar-background-hover": "#1d7575",
  "step-background": "#99CC33",
  "step-collapsable": "#F8F9FA",
  "header-grey": "#F8F9FA",
};

module.exports = {
  colors,
  content: ["./public/**/*.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
  theme: {
    extend: {
      colors: colors,
      fontFamily: {
        saira: ["Saira"],
        calibri: ["calibri", "roboto"],
      },
    },
  },
  plugins: [],
};
