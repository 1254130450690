<template>
  <div class="pt-4">
    <template v-for="(pico, p) in currentPicoQuestions">
      <div class="pb-4" :key="'pico-' + p">
        <div class="flex gap-2 items-center">
          <v-icon color="primary" v-tooltip="getResponses(pico)"> fas fa-info-circle </v-icon>
          <span class="font-bold">PICO {{ p + 1 }}</span>
        </div>

        <FormulateInput
          :disabled="context.attributes.disabled"
          ignored
          :value="getCurrentValue(pico)"
          @input="(e) => handleChange(e, pico)"
          type="custom-boxlist"
          :options="context.options"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getResponses(pico) {
      const response = pico["pico-question"];

      return {
        html: true,
        content: `
        <p>${this.translate("misc.population")}: ${response.P ? response.P : ""}</p>
        <p>${this.translate("misc.intervention")}: ${response.I ? response.I : ""}</p>
        <p>${this.translate("misc.comparator")}: ${response.C ? response.C : ""}</p>
        <p>${this.translate("misc.outcomes")}: ${response.O ? response.O : ""}</p>`,
      };
    },

    handleChange(value, pico) {
      const idPico = pico["pico-question"]?.idPico;

      this.context.model = { ...this.context.model, [idPico]: value };
    },
    getCurrentValue(pico) {
      const idPico = pico["pico-question"]?.idPico;
      return this.context.model?.[idPico] || { base: "" };
    },
  },
  computed: {
    currentPicoQuestions() {
      const values = this.context.attributes?.currentValues?.[this.getFrom] || [];

      return [...values];
    },
    getFrom() {
      return this.context?.slotProps?.component?.getFrom;
    },
  },
};
</script>

<style lang="scss" scoped>
.customBordered {
  box-shadow: 2px 0 0 0 #888, 0 2px 0 0 #888, 2px 2px 0 0 #888,
    /* Just to fix the corner */ 2px 0 0 0 #888 inset, 0 2px 0 0 #888 inset;
}
</style>