import store from "@/store";

const getTranslation = (value) => {
  try {
    const currentLanguage = store?.state.currentLanguage;
    const existLang = value[currentLanguage];

    const defaultLang = value["en"]; //TODO: this is a default language, we need to get it from the config file
    return existLang ? existLang : defaultLang ? defaultLang : value;
  } catch (error) {
    // Se genera un error dado que el proceso de obtención es asyncrono...
    return "";
  }
};
export default getTranslation;
