import router from "@/router";
import store from "@/store";
import moment from "moment";
import { mapGetters } from "vuex";
import interpole from "string-interpolation-js";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        interpolate(value, src) {
          return interpole(value, src?.interpolation || {});
        },
        async uploadFile(file, progress, error, option) {
          return new Promise((resolve, reject) => {
            const storage = getStorage();

            // Create a reference to 'mountains.jpg'
            const fileRef = ref(storage, "le/" + file.name);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progressStatus = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progress(progressStatus);
              },
              (error) => {
                reject(error);
              },
              async () => {
                progress(100);

                const url = await getDownloadURL(uploadTask.snapshot.ref);

                return resolve({ url });
              },
            );
          });
        },
        checkValidForm(formName) {
          try {
            return this.$formulate.registry.get(formName).isValid;
          } catch (error) {
            return false;
          }
        },
        updateQueries: function (newValues) {
          router
            .replace({
              query: {
                ...router.app.$route.query,
                ...newValues,
              },
            })
            .catch((e) => {});
        },
        isMeEmail(email) {
          return store.state.user?.email === email;
        },
        getDateFromObjectId(objectId) {
          return moment(new Date(parseInt(objectId.substring(0, 8), 16) * 1000)).format("LLLL");
        },
      },
      computed: {
        ...mapGetters(["isAdmin"]),
        currentPagination() {
          return {
            page: parseInt(router.app.$route.query.page || 1),
            itemsPerPage: parseInt(router.app.$route.query.itemsPerPage || 5),
          };
        },
      },
    });
  },
};
